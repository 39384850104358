<template>
  <div class="status-bar" :class="{ eligible: $parent.getStatus.is_eligible, prizes: $parent.getPrizes }">
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" class="party">
      <path
        d="M0.490649 25.0623C0.952619 25.563 2.85545 24.6899 4.35736 23.9898C5.48278 23.4668 9.98648 21.6189 12.2251 20.6604C12.8295 20.4019 13.7087 20.0641 14.3416 19.2317C14.9033 18.491 16.393 15.3447 13.3933 12.1577C10.3487 8.92186 7.21262 9.81528 6.0404 10.6456C5.3505 11.134 4.7888 12.235 4.53645 12.7947C3.47005 15.1595 1.94982 19.4922 1.33115 21.1956C0.87732 22.4513 0.0327484 24.5658 0.490649 25.0623Z"
        fill="#FFC107"/>
      <path
        d="M4.23505 13.507C4.26355 13.8611 4.33477 14.4371 4.57899 15.5462C4.74587 16.3073 5.01857 17.1051 5.2404 17.6322C5.90588 19.2175 6.84 19.8566 7.78632 20.3694C9.39406 21.2404 10.4869 21.4033 10.4869 21.4033L9.17631 21.9385C9.17631 21.9385 8.38261 21.7736 7.29993 21.2404C6.26813 20.7317 5.19359 19.8708 4.40804 18.1857C4.06818 17.4551 3.87077 16.7469 3.7568 16.1852C3.61638 15.4892 3.59399 15.0944 3.59399 15.0944L4.23505 13.507Z"
        fill="#FF8F00"/>
      <path
        d="M2.62546 17.6342C2.62546 17.6342 2.78827 18.955 3.87909 20.6218C5.15714 22.5714 6.94194 22.8909 6.94194 22.8909L5.75546 23.3793C5.75546 23.3793 4.43061 22.9743 3.17087 21.2547C2.38532 20.1822 2.16553 18.9001 2.16553 18.9001L2.62546 17.6342Z"
        fill="#FF8F00"/>
      <path
        d="M1.4958 20.7642C1.4958 20.7642 1.79293 21.9039 2.44416 22.7546C3.21954 23.7701 4.20657 24.0652 4.20657 24.0652L3.29687 24.4681C3.29687 24.4681 2.60697 24.3236 1.85194 23.3671C1.27804 22.6406 1.11523 21.8062 1.11523 21.8062L1.4958 20.7642Z"
        fill="#FF8F00"/>
      <path opacity="0.44"
            d="M1.00147 23.6582C0.960766 23.5666 0.960766 23.4628 1.0035 23.3732L6.18693 12.6238L7.03965 15.8291L1.58555 23.707C1.43902 23.9268 1.10933 23.8983 1.00147 23.6582Z"
            fill="#FFFDE7"/>
      <path
        d="M7.45062 16.9056C9.87241 19.7385 12.63 19.3844 13.5478 18.668C14.4677 17.9496 15.1942 15.481 12.7826 12.695C10.255 9.77662 7.39364 10.609 6.73223 11.2399C6.07082 11.8708 5.22828 14.3068 7.45062 16.9056Z"
        fill="url(#paint0_linear_1_2008)"/>
      <path
        d="M15.7678 18.0718C14.8845 17.331 14.4144 17.4633 13.7835 17.7258C12.9695 18.0636 11.6894 18.3139 9.95142 17.7258L10.4744 16.4661C11.5062 16.8141 12.2531 16.6452 12.8983 16.2646C13.7286 15.7762 14.8642 15.1066 16.6307 16.5902C17.3674 17.2089 18.1224 17.62 18.6759 17.4327C19.0789 17.2984 19.2926 16.6981 19.4004 16.2198C19.4106 16.1771 19.4269 16.055 19.4391 15.9471C19.5368 15.2002 19.6996 13.5884 20.9003 12.7642C22.1845 11.883 23.5338 11.883 23.5338 11.883L23.778 14.3088C23.1573 14.2173 22.7258 14.3434 22.3615 14.5449C20.9899 15.3081 22.1845 18.2386 20.0496 19.2236C17.9962 20.1781 16.3172 18.5317 15.7678 18.0718Z"
        fill="#03A9F4"/>
      <path
        d="M8.21356 14.9784L7.33032 14.1867C8.9523 12.3755 8.52493 11.0445 8.21356 10.0758C8.15047 9.88043 8.09145 9.69523 8.05278 9.51818C7.9144 8.89136 7.8859 8.34595 7.92864 7.86973C7.3059 7.09436 7.03116 6.28235 7.01284 6.2274C6.63431 5.08163 6.91923 3.96436 7.5725 2.91627C8.89328 0.789581 11.2845 0.789581 11.2845 0.789581L12.0823 2.92441C11.4758 2.89999 9.48754 2.93052 8.877 3.89313C8.1057 5.10605 8.61244 5.85497 8.64907 5.94045C8.79763 5.74711 8.94823 5.59244 9.08255 5.47237C10.0574 4.60745 10.904 4.48331 11.4433 4.53215C12.0497 4.5871 12.5992 4.89237 12.992 5.393C13.4214 5.94248 13.5985 6.65681 13.4621 7.30601C13.3298 7.93893 12.9086 8.47416 12.2756 8.81403C11.1706 9.40828 10.2507 9.32687 9.63406 9.12133C9.63813 9.13557 9.64017 9.15185 9.64424 9.1661C9.66663 9.26786 9.7114 9.41031 9.76431 9.57516C10.1245 10.6904 10.7941 12.4609 8.21356 14.9784ZM9.70936 7.34061C9.8274 7.42608 9.95154 7.49731 10.0798 7.54819C10.5071 7.71914 10.9732 7.66215 11.5023 7.37724C11.8137 7.21036 11.8503 7.03127 11.8625 6.97225C11.8991 6.7952 11.8381 6.5693 11.7058 6.40038C11.5898 6.25182 11.4555 6.17449 11.2947 6.15821C10.9894 6.13175 10.5763 6.32508 10.1632 6.69344C9.96579 6.87049 9.81519 7.08825 9.70936 7.34061Z"
        fill="#F44336"/>
      <path
        d="M11.7484 15.3101L10.4846 15.2755C10.4846 15.2755 11.085 11.885 13.0285 11.3152C13.3928 11.2093 13.7917 11.1015 14.1926 11.0425C14.4307 11.0058 14.8072 10.9509 14.9924 10.8817C15.0351 10.5622 14.9008 10.1551 14.7502 9.69318C14.6322 9.335 14.5101 8.96664 14.4449 8.56369C14.3188 7.77814 14.5284 7.08417 15.0351 6.60592C15.6538 6.02591 16.653 5.84071 17.7805 6.09714C18.4236 6.24367 18.8978 6.55911 19.315 6.83588C19.9113 7.23273 20.2593 7.43421 20.9878 6.94374C21.869 6.34949 20.7172 4.02336 20.1046 2.68019L22.39 1.72775C22.6973 2.39934 24.1809 5.85496 23.202 7.82698C22.8723 8.49043 22.3045 8.93001 21.5597 9.09486C19.9397 9.45711 18.9914 8.82622 18.2994 8.36629C17.9718 8.14853 17.6848 7.97758 17.3735 7.88804C15.2101 7.2714 18.2303 10.4543 16.8159 11.885C15.9672 12.7418 13.8934 12.9677 13.7591 13.0002C12.4241 13.3218 11.7484 15.3101 11.7484 15.3101Z"
        fill="#F48FB1"/>
      <path
        d="M7.9265 7.86972C7.88784 8.31745 7.86952 8.58404 7.98552 9.16609C8.54518 9.57718 9.76421 9.57718 9.76421 9.57718C9.7113 9.41234 9.66449 9.26988 9.64414 9.16812C9.64007 9.15388 9.63803 9.1376 9.63396 9.12335C8.39458 8.50468 7.9265 7.86972 7.9265 7.86972Z"
        fill="#C92B27"/>
      <path d="M5.39093 9.87432L3.28662 8.84252L4.3347 7.3284L5.98518 8.42125L5.39093 9.87432Z" fill="#FFC107"/>
      <path
        d="M2.2895 7.01701C1.21496 6.87252 0.120072 5.96079 0 5.857L1.05622 4.61761C1.37574 4.88828 2.05343 5.34211 2.50726 5.40317L2.2895 7.01701Z"
        fill="#FB8C00"/>
      <path
        d="M4.18609 4.30423L2.6394 3.79749C2.81646 3.25615 2.86327 2.67207 2.77169 2.10834L4.37942 1.84988C4.51171 2.668 4.44455 3.51664 4.18609 4.30423Z"
        fill="#03A9F4"/>
      <path d="M13.8452 3.09433L15.4357 2.74641L15.895 4.84584L14.3045 5.19376L13.8452 3.09433Z" fill="#FB8C00"/>
      <path
        d="M17.7907 3.59192L16.6714 2.40952C17.2575 1.85394 17.3918 1.1274 17.3918 1.11926L18.9996 1.38179C18.9792 1.51 18.7737 2.66188 17.7907 3.59192Z"
        fill="#FFC107"/>
      <path d="M18.4128 9.86237L19.8348 9.41799L20.3204 10.972L18.8985 11.4164L18.4128 9.86237Z" fill="#FB8C00"/>
      <path
        d="M18.8269 22.9784L17.209 22.7871C17.2782 22.2111 16.8487 21.505 16.7307 21.3483L18.0332 20.3714C18.1309 20.4996 18.9795 21.6739 18.8269 22.9784Z"
        fill="#F44336"/>
      <path
        d="M23.4709 20.9148C22.8624 20.8232 22.2396 20.7866 21.625 20.809L21.5701 19.1809C22.2844 19.1565 23.0069 19.1972 23.713 19.305L23.4709 20.9148Z"
        fill="#FB8C00"/>
      <path d="M21.282 23.1559L22.4259 21.9973L24.0001 23.5516L22.8562 24.7101L21.282 23.1559Z" fill="#F48FB1"/>
      <path d="M17.9211 12.8641L19.0974 14.2091L17.7525 15.3853L16.5762 14.0404L17.9211 12.8641Z" fill="#F44336"/>
      <defs>
        <linearGradient id="paint0_linear_1_2008" x1="14.1124" y1="12.5604" x2="8.05444" y2="16.1951"
                        gradientUnits="userSpaceOnUse">
          <stop offset="0.0235" stop-color="#8F4700"/>
          <stop offset="1" stop-color="#703E2D"/>
        </linearGradient>
      </defs>
    </svg>

    <template v-if="$parent.getStatus.is_eligible">
      <span class="info">
        Gratulacje! Masz nagrodę do odebrania
      </span>

      <button class="pulse" @click="$emit('open')">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_2036)">
            <path
              d="M0.75 10.5C0.75 10.9148 1.08516 11.25 1.5 11.25H5.25V7.5H0.75V10.5ZM6.75 11.25H10.5C10.9148 11.25 11.25 10.9148 11.25 10.5V7.5H6.75V11.25ZM11.25 3.75H10.2633C10.4086 3.46641 10.5 3.15234 10.5 2.8125C10.5 1.67578 9.57422 0.75 8.4375 0.75C7.4625 0.75 6.83203 1.24922 6.02344 2.35078C5.21484 1.24922 4.58437 0.75 3.60938 0.75C2.47266 0.75 1.54688 1.67578 1.54688 2.8125C1.54688 3.15234 1.63594 3.46641 1.78359 3.75H0.75C0.335156 3.75 0 4.08516 0 4.5V6.375C0 6.58125 0.16875 6.75 0.375 6.75H11.625C11.8313 6.75 12 6.58125 12 6.375V4.5C12 4.08516 11.6648 3.75 11.25 3.75ZM3.60703 3.75C3.08906 3.75 2.66953 3.33047 2.66953 2.8125C2.66953 2.29453 3.08906 1.875 3.60703 1.875C4.07344 1.875 4.41797 1.95234 5.625 3.75H3.60703ZM8.4375 3.75H6.41953C7.62422 1.95703 7.95937 1.875 8.4375 1.875C8.95547 1.875 9.375 2.29453 9.375 2.8125C9.375 3.33047 8.95547 3.75 8.4375 3.75Z"
              fill="#E1F5FE"/>
          </g>
          <defs>
            <clipPath id="clip0_1_2036">
              <rect width="12" height="12" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        Odbierz nagrodę
      </button>
    </template>
    <div class="break"></div>
    <template v-if="$parent.getPrizes">
      <span class="v-space alt-or" v-if="$parent.getStatus.is_eligible">lub sprawdź swoje dotychczasowe nagrody</span>
      <span class="v-space alt-and" v-else>Sprawdź swoje dotychczasowe nagrody</span>
      <button class="outline" @click="$emit('open')">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.26312 5.64595C5.30469 4.9722 5.64156 4.3072 6.22563 3.90032C6.11188 3.66407 5.87781 3.50001 5.6 3.50001H4.95687L5.62844 3.01876C5.78594 2.9072 5.82312 2.68845 5.70937 2.53095L5.50594 2.24657C5.39437 2.08907 5.17562 2.05189 5.01812 2.16564L4.31812 2.66657L4.56969 1.9972C4.6375 1.81564 4.54562 1.61439 4.36406 1.54657L4.03594 1.42407C3.85437 1.35626 3.65312 1.44814 3.58531 1.6297L3.15 2.78907L2.71469 1.62751C2.64687 1.44595 2.44563 1.35407 2.26406 1.42189L1.93594 1.54439C1.75437 1.6122 1.6625 1.81564 1.7325 1.99501L1.98406 2.66439L1.28187 2.16564C1.12437 2.05407 0.905625 2.08907 0.794062 2.24657L0.590625 2.53095C0.479062 2.68845 0.514063 2.9072 0.671562 3.01876L1.34312 3.50001H0.7C0.312812 3.50001 0 3.81282 0 4.20001V11.9C0 12.2872 0.312812 12.6 0.7 12.6H4.39469C4.27437 12.3922 4.2 12.1559 4.2 11.9V7.00001C4.2 6.34595 4.655 5.79689 5.26312 5.64595ZM4.9 11.9C4.9 12.2872 5.21281 12.6 5.6 12.6H9.1V9.80001H4.9V11.9ZM9.8 12.6H13.3C13.6872 12.6 14 12.2872 14 11.9V9.80001H9.8V12.6ZM13.3 6.30001H12.8537C12.9106 6.13376 12.95 5.96095 12.95 5.77939C12.95 5.00282 12.3594 4.20001 11.3728 4.20001C10.3206 4.20001 9.7125 5.24345 9.45 5.8472C9.18531 5.24345 8.57719 4.20001 7.52719 4.20001C6.54063 4.20001 5.95 5.00282 5.95 5.77939C5.95 5.96095 5.98719 6.13376 6.04625 6.30001H5.6C5.21281 6.30001 4.9 6.61282 4.9 7.00001V9.10001H9.1V6.30001H9.43469L9.45 6.29564L9.46531 6.30001H9.8V9.10001H14V7.00001C14 6.61282 13.6872 6.30001 13.3 6.30001ZM7.35 6.30001C7.29094 6.26939 7.23844 6.23439 7.1925 6.19501C7.03281 6.05501 7 5.89314 7 5.77939C7 5.5672 7.14 5.25001 7.52719 5.25001C7.93625 5.25001 8.30594 5.84939 8.50062 6.30001H7.35ZM11.7075 6.19501C11.6616 6.23439 11.6091 6.26939 11.55 6.30001H10.3994C10.5919 5.85595 10.9637 5.25001 11.3728 5.25001C11.76 5.25001 11.9 5.5672 11.9 5.77939C11.9 5.89314 11.8672 6.05501 11.7075 6.19501Z"
            fill="#0277BD"/>
        </svg>
        Moje nagrody
      </button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'StatusBar'
}
</script>

<style scoped lang="scss">
.status-bar {
  border-radius: 6px;
  background: #E1F5FE;
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  color: #0277BD;
  position: sticky;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 1;

  @media (max-width: 996px) {
    column-gap: 10px;
    justify-content: center;
  }

  @media (max-width: 996px) and (orientation: landscape) {
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
    padding: 10px 0;
  }
  @media (max-width: 480px) {
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
    padding: 10px 0;
  }

  .break {
    display: none;
  }

  span {
    font-weight: 500;
    &.info {
      margin: 0 15px 0 25px;
      @media (max-width: 996px) {
        margin: 0 10px;
      }
      @media (max-width: 996px) and (orientation: landscape) {
        margin: 0;
      }
      @media (max-width: 480px) {
        margin: 0;
      }
    }

    &.v-space {
      margin: 0 15px;
      &.alt-or {
        @media (max-width: 996px) {
          margin: 0;
        }
        @media (max-width: 996px) and (orientation: landscape) {
          margin: 0;
        }
        @media (max-width: 480px) {
          margin: 0;
        }
      }
      &.alt-and {
        @media (max-width: 996px) {
          margin: 0;
        }
        @media (max-width: 996px) and (orientation: landscape) {
          margin: 0;
        }
        @media (max-width: 480px) {
          margin: 0;
        }
      }
    }
  }

  button {
    display: inline-flex;
    padding: 6px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 1px solid #0277BD;
    background: #0277BD;
    color: #E1F5FE;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;

    //@media (min-width: 680px) and (max-width: 996px) {
    //  margin-top: 5px;
    //}

    //@media (max-width: 480px) {
    //  margin-top: 15px;
    //}

    &.pulse {
      box-shadow: 0 0 1px 1px #0277BD;
      animation: pulse-animation-1 2s infinite;
      //@media (min-width: 680px) and (max-width: 996px) {
      //  order: 2;
      //}
      //@media (max-width: 480px) {
      //  margin-bottom: 15px;
      //}
    }

    &.outline {
      background: none;
      color: #0277BD;
      //@media (min-width: 680px) and (max-width: 996px) {
      //  order: 10;
      //}
      svg {
        path {
          fill: #0277BD;
        }
      }
    }
  }

  &.eligible {
    span {
      &.v-space {
        margin: 0 15px;
        &.alt-or {
          @media (max-width: 996px) {
            margin: 0;
          }
          @media (max-width: 996px) and (orientation: landscape) {
            margin: 0;
          }
          @media (max-width: 480px) {
            margin: 10px 0;
          }
        }
        &.alt-and {
          @media (max-width: 996px) {
            margin: 0;
          }
          @media (max-width: 996px) and (orientation: landscape) {
            margin: 0;
          }
          @media (max-width: 480px) {
            margin: 10px 0;
          }
        }
      }
    }
    .break {
      @media (min-width: 480px) and (max-width: 996px) {
        display: block;
        width: 100%;
        height: 10px;
      }
    }
  }
}
</style>
