<template>
  <transition name="fade">
    <div class="product-dialog">
      <div class="wrapper">
        <div class="header-modal">
          <div class="picture">
            <template v-if="data.prize_code && showCode">
              <svg ref="ean8" v-if="data.prize_code.type === 'EAN8'"></svg>
              <canvas ref="qr" v-if="data.prize_code.type === 'QR'"></canvas>
              <code>{{ data.prize_code.code }}</code>
            </template>
            <img :src="data.image_url" :alt="data.title" v-else/>
          </div>

          <button type="button" class="close" @click="$emit('close')">
            Zamknij
          </button>
        </div>

        <div class="content-modal">
          <h3>{{ data.title }}</h3>
          <p class="text-intro">{{ data.description }}</p>

          <p class="info">Nagrodę znajdziesz w zakładce "Odebrane".</p>

          <button class="collect" @click="showCode = !showCode" v-if="data.prize_code">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 10.5V1.5H0.421875V10.5H0ZM0.629461 10.4936V1.5H0.84375V10.4936H0.629461ZM1.26562 10.4936V1.5H1.47321V10.4936H1.26562ZM2.31696 10.4936V1.5H2.52455V10.4936H2.31696ZM3.16071 10.4936V1.5H3.57588V10.4936H3.16071ZM4.21205 10.4936V1.5H4.41963V10.4936H4.21205ZM4.63392 10.4936V1.5H4.84151V10.4936H4.63392ZM5.0558 10.4936V1.5H5.26338V10.4936H5.0558ZM5.89287 10.4936V1.5H6.31474V10.4936H5.89287ZM6.9442 10.4936V1.5H7.36608V10.4936H6.9442ZM7.78793 10.4936V1.5H8.20983V10.4936H7.78793ZM8.6317 10.4936V1.5H9.0536V10.4936H8.6317ZM9.26116 10.4936V1.5H9.68304V10.4936H9.26116ZM10.3192 10.4936V1.5H10.9487V10.4936H10.3192ZM11.1562 10.4936V1.5H11.3705V10.4936H11.1562ZM11.5781 10.5V1.5H12V10.5H11.5781Z" fill="#0277BD"/>
            </svg>
            {{ showCode ? 'Ukryj' : 'Pokaż' }} kod
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'

export default {
  name: 'PrizeReceivedModal',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showCode: false
    }
  },
  updated () {
    const ean8 = this.$refs.ean8
    const qr = this.$refs.qr
    if (ean8 && this.data.prize_code) {
      JsBarcode(ean8).EAN8(this.data.prize_code.code, {
        displayValue: false,
        height: 100
      }).render()
    }
    if (qr && this.data.prize_code) {
      QRCode.toCanvas(qr, this.data.prize_code.code, { margin: 0, width: 120 })
    }
  }
}
</script>

<style scoped lang="scss">
.product-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .1);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    background: #fff;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    max-width: 450px;
    margin: 0 10px;
    width: 100%;
    position: relative;
    overflow: auto;
    max-height: 90vh;

    .header-modal {
      .picture {
        height: 225px;
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        canvas {
          margin: 10px auto 0 auto;
        }
        code {
          position: absolute;
          bottom: 0;
          padding: 5px 10px;
          background: #eee;
          border-radius: 5px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        padding: 8px 20px;
        background: #0277BD;
        border: 0;
        border-radius: 15px;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
          path {
            fill: #555;
          }
        }

        &:hover {
          svg {
            path {
              fill: #111;
            }
          }
        }
      }
    }

    .content-modal {
      padding: 25px;
      @media (max-width: 996px) {
        padding: 15px;
      }

      h3 {
        color: #01579B;
        font-size: 16px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 10px;
      }

      .text-intro {
        margin: 0;
        color: #555;
        font-size: 14px;
        &::v-deep {
          a {
            color: #333;
            text-decoration: underline;
            &:hover {
              color: #131313;
              text-decoration: none;
            }
          }
        }
      }

      .info {
        color: #01579B;
        border-radius: 5px;
        text-align: center;
        font-size: 13px;
        padding: 5px;
        margin: 15px 0 0 0;
      }

      button {
        display: flex;
        padding: 8px 24px;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        background: #F5F5F5;
        border: none;
        font-size: 13px;
        color: #757575;
        cursor: pointer;
        transition: all .2s;
        &:hover {
          background: #f1f1f1;
        }

        &.collect {
          background: #E1F5FE;
          color: #0277BD;
          font-weight: 600;
          animation: pulse-animation-2 2s infinite;
          margin-top: 25px;
          &:hover {
            background: #0277BD;
            color: #E1F5FE;
            svg {
              path {
                fill: #E1F5FE;
              }
            }
          }
        }
      }
    }

    .footer-modal {
      position: sticky;
      bottom: 0;
      background: #fff;
      padding: 10px 25px;
      border-top: 1px solid #eee;
      @media (max-width: 996px) {
        border-top: 1px solid #eee;
        padding: 10px 15px;
      }
      @media (max-width: 480px) {
        margin-top: auto
      }
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
