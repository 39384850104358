import Vue from 'vue'
import Widget from './components/Widget'
import store from './store'
import settings from './services/settings'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

import 'simplebar'
import 'simplebar/dist/simplebar.css'

import { VueRecaptcha } from 'vue-recaptcha'

import ResizeObserver from 'resize-observer-polyfill'
window.ResizeObserver = ResizeObserver

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.prototype.$settings = settings

Vue.use(VueToast, {
  position: 'top',
  duration: 5000
})
Vue.$toast.clear()

Vue.component('vue-recaptcha', VueRecaptcha)

new Vue({
  store,
  render: h => h(Widget)
}).$mount('#app')
