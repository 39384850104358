<template>
  <div class="chatHeader">
    <div class="chatHeader__title">
      <picture>
        <img src="@/assets/img/avatar.png" alt="Avatar"/>
      </picture>
      <div class="chatHeader__title__block">
        <span class="chatHeader__title--name">
          {{ getSettings.translations.title }}
        </span>
        <span class="chatHeader__title--subtitle">
          {{ getSettings.translations.subtitle }}
          <a href="https://aica.si/" target="_blank">AICA</a>
        </span>
      </div>
    </div>
    <div class="chatHeader__actions">
      <span @click="$emit('close')" class="close">
        Wyloguj
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.585693" y="2.19666" width="2" height="15" rx="1" transform="rotate(-45 0.585693 2.19666)"
                fill="#888888"/>
          <rect x="0.585693" y="2.19666" width="2" height="15" rx="1" transform="rotate(-45 0.585693 2.19666)"
                fill="#888888"/>
          <rect x="0.585693" y="2.19666" width="2" height="15" rx="1" transform="rotate(-45 0.585693 2.19666)"
                fill="#888888"/>
          <rect x="0.585693" y="2.19666" width="2" height="15" rx="1" transform="rotate(-45 0.585693 2.19666)"
                fill="#888888"/>
          <rect x="11.5857" y="1.19666" width="2" height="15" rx="1" transform="rotate(45 11.5857 1.19666)"
                fill="#888888"/>
          <rect x="11.5857" y="1.19666" width="2" height="15" rx="1" transform="rotate(45 11.5857 1.19666)"
                fill="#888888"/>
          <rect x="11.5857" y="1.19666" width="2" height="15" rx="1" transform="rotate(45 11.5857 1.19666)"
                fill="#888888"/>
          <rect x="11.5857" y="1.19666" width="2" height="15" rx="1" transform="rotate(45 11.5857 1.19666)"
                fill="#888888"/>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import settings from '../mixins/settings'

export default {
  name: 'Header',
  mixins: [settings]
}
</script>

<style scoped lang="scss">
.chatHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #FFFFFF;
  border-bottom: 1px solid #F1F1F1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  &__title {
    display: flex;
    align-items: center;
    padding: 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    gap: 10px;

    picture {
      width: 38px;
      height: 38px;
      border-radius: 100%;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 100%;
      }
    }

    &__block {
      display: flex;
      flex-direction: column;
    }

    &--name {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;

      color: #2C2C2E;
    }

    &--subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #999;

      a {
        color: #999;
        text-decoration: none;
      }
    }
  }

  &__actions {
    span {
      background: none;
      border: none;
      cursor: pointer;
      color: #aaa;
      font-size: 13px;
      display: inline-flex;
      align-items: center;

      svg {
        margin-left: 5px;
        transition: all .2s;
        rect {
          transition: all .2s;
          fill: #aaa;
        }
      }

      &:hover {
        svg {
          transform: rotate(90deg);
          rect {
            fill: #131313;
          }
        }
      }
    }
  }
}
</style>
