<template>
  <div class="auth-container">
    <div class="auth-container__inner">
      <form @submit.prevent="login" v-if="type === 'login'">
        <h1>Logowanie</h1>
        <div class="oauth-options" v-if="authMethods.enabled_oauth_types && authMethods.enabled_oauth_types.length">
          <a @click="oauth(provider)" class="oauth-options__option"
             v-for="(provider, index) in authMethods.enabled_oauth_types" :key="index">
            <img :src="require(`../../assets/img/${provider}.svg`)" :alt="provider">
          </a>
        </div>

        <div class="input-box" :class="{ invalid: $v.form.phone_number.$error }">
          <span class="prefix">48</span>
          <input type="text"
                 inputmode="numeric"
                 autocomplete="off"
                 placeholder="Numer telefonu"
                 class="phone-number"
                 v-model.number="form.phone_number"
                 @input="evt => form.phone_number = onlyDigitsStringWithLength(evt.target.value, 9)"
                 @paste="evt => form.phone_number = onlyDigitsStringWithLength(evt.target.value, 9)"/>
        </div>
        <input type="password" inputmode="numeric" maxlength="4" minlength="1" autocomplete="off" placeholder="PIN" v-model.number="form.pin_code"
               :class="{ invalid: $v.form.pin_code.$error }"/>
        <div class="link">
          <a @click="type = 'remind'">Przypomnij PIN</a>
        </div>
        <button :disabled="$v.form.$invalid">Zaloguj</button>

        <div class="alternative">
          <span>lub</span>
        </div>
        <button type="button" class="outline" @click="type = 'register'">Uzyskaj PIN</button>
      </form>
      <form @submit.prevent="registerWithPhone" v-if="type === 'register' && doesAuthTypeEnabled('phone_number')">
        <h1>Uzyskaj PIN</h1>
        <div class="oauth-options" v-if="authMethods.enabled_oauth_types && authMethods.enabled_oauth_types.length">
          <a @click="oauth(provider)" class="oauth-options__option"
             v-for="(provider, index) in authMethods.enabled_oauth_types" :key="index">
            <img :src="require(`../../assets/img/${provider}.svg`)" :alt="provider">
          </a>
        </div>

        <div class="input-box" :class="{ invalid: $v.form.phone_number.$error }">
          <span class="prefix">48</span>
          <input type="text" inputmode="numeric" autocomplete="off" placeholder="Numer telefonu" v-model.number="form.phone_number"
                 @input="evt => form.phone_number = onlyDigitsStringWithLength(evt.target.value, 9)"
                 @paste="evt => form.phone_number = onlyDigitsStringWithLength(evt.target.value, 9)"/>
        </div>
        <div class="form-group">
          <input type="checkbox" name="gdpr" id="gdpr" v-model="form.gdpr" @change="$v.form.gdpr.$touch()" />
          <label for="gdpr">Wyrażam zgodę na przesłanie numeru PIN na podany numer telefonu. Administrator przetwarza dane osobowe w celu komunikacji z Tobą na podstawie Twojej zgody (art. 6 ust 1f) RODO. <span class="req">*</span></label>
        </div>
        <button :disabled="$v.form.$invalid">Uzyskaj PIN</button>

        <div class="alternative">
          <span>lub</span>
        </div>
        <button type="button" class="outline" @click="type = 'login'">Zaloguj</button>
      </form>
      <form @submit.prevent="registerWithCaptcha" v-if="type === 'register' && doesAuthTypeEnabled('recaptcha')">
        <h1>Uzyskaj PIN</h1>
        <div class="oauth-options" v-if="authMethods.enabled_oauth_types">
          <a @click="oauth(provider)" class="oauth-options__option"
             v-for="(provider, index) in authMethods.enabled_oauth_types" :key="index">
            <img :src="require(`../../assets/img/${provider}.svg`)" :alt="provider">
          </a>
        </div>

        <input type="text" placeholder="Imię" v-model="shortForm.user_name" />
        <vue-recaptcha ref="recaptcha" sitekey="6LcZuMInAAAAAK8FEX_cFl6cPwC35DTZWaoLAOFh" @verify="saveRecaptchaResponse" size="visible"/>
        <button>Uzyskaj PIN</button>

        <div class="alternative">
          <span>lub</span>
        </div>
        <button type="button" class="outline" @click="type = 'login'">Zaloguj</button>
      </form>
      <form @submit.prevent="reset" v-if="type === 'remind'">
        <h1>Przypomnienie PIN</h1>

        <div class="input-box">
          <span class="prefix">48</span>
          <input type="text" inputmode="numeric" autocomplete="off" placeholder="Numer telefonu" v-model="form.phone_number" required
                 @input="evt => form.phone_number = onlyDigitsStringWithLength(evt.target.value, 9)"
                 @paste="evt => form.phone_number = onlyDigitsStringWithLength(evt.target.value, 9)"/>
        </div>
        <button :disabled="$v.form.$invalid">Przypomnij</button>

        <div class="alternative">
          <span>lub</span>
        </div>
        <button type="button" class="outline" @click="type = 'login'">Zaloguj</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { VueRecaptcha } from 'vue-recaptcha'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric, requiredIf, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'Authorization',
  mixins: [validationMixin],
  data () {
    return {
      type: 'login',
      form: {
        phone_number: '',
        pin_code: '',
        gdpr: false
      },
      shortForm: {
        user_name: '',
        google_recaptcha_response: ''
      },
      toggle: false,
      resetContainer: false,
      allowSubmit: false
    }
  },
  components: {
    VueRecaptcha
  },
  computed: {
    ...mapGetters({
      authMethods: 'auth/getAuthState'
    })
  },
  watch: {
    type () {
      this.$v.form.$reset()
      this.form.phone_number = ''
      this.form.pin_code = ''
    }
  },
  validations () {
    const validations = {
      form: {
        phone_number: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(9),
          numeric
        },
        pin_code: {},
        gdpr: {}
      }
    }

    if (this.type === 'login') {
      validations.form.pin_code = {
        required: requiredIf(function () {
          return this.type === 'login'
        }),
        minLength: minLength(4),
        maxLength: maxLength(4),
        numeric
      }
    }

    if (this.type === 'register') {
      validations.form.gdpr = {
        required,
        sameAs: sameAs(() => true)
      }
    }

    return validations
  },
  created () {
    window.scrollTo(0, 0)
  },
  destroyed () {
    this.fetchMe()
  },
  mounted () {
    this.setAuthMethods()
    this.setLocationIdWrapper(new URL(window.location).searchParams.get('location'))
    this.checkOauthWrapper(new URL(window.location).searchParams.get('access_token'))
  },
  methods: {
    ...mapActions({
      setAuthMethods: 'auth/setAuthMethods',
      authLogin: 'auth/authLogin',
      authRegisterWithPhone: 'auth/authRegisterWithPhone',
      authRegisterWithCaptcha: 'auth/authRegisterWithCaptcha',
      authReset: 'auth/authReset',
      oauth: 'auth/oauth',
      setLocationId: 'chat/setLocationId',
      checkOauth: 'auth/checkOauth',
      fetchMe: 'chat/fetchMe'
    }),
    checkOauthWrapper (data) {
      this.checkOauth(data)
    },
    setLocationIdWrapper (data) {
      this.setLocationId(data)
    },
    oauthWrapper (provider) {
      const internalCallback = provider === 'facebook' ? encodeURIComponent(process.env.VUE_APP_APP_URL) : process.env.VUE_APP_APP_URL
      const encodedCallback = encodeURIComponent(`${process.env.VUE_APP_BASE_URL}/auth/login/oauth/${provider}/token?callback=${internalCallback}`)
      location.href = `${process.env.VUE_APP_BASE_URL}/auth/login/oauth/${provider}/redirect?callback=${encodedCallback}`
    },
    login () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.authLogin({ pin_code: this.form.pin_code, phone_number: `0048${this.form.phone_number}` })
    },
    registerWithPhone () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.authRegisterWithPhone(`0048${this.form.phone_number}`).then(() => {
        this.type = 'login'
      })
    },
    registerWithCaptcha () {
      this.authRegisterWithCaptcha(this.shortForm)
    },
    saveRecaptchaResponse (response) {
      this.shortForm.google_recaptcha_response = response
    },
    doesAuthTypeEnabled (type) {
      return this.authMethods.enabled_auth_types?.includes(type)
    },
    reset () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.authReset(`0048${this.form.phone_number}`).then(() => {
        this.type = 'login'
      })
    },
    oauth (provider) {
      this.oauthWrapper(provider)
    },
    onlyDigitsString (value) {
      value = value.replace(/\D/g, '')
      value = value.replace(/\s+/g, '')
      return value
    },
    stripPhoneNumber (value) {
      if (value.slice(0, 4) === '0048') {
        value = value.slice(4)
      }

      if (value.slice(0, 2) === '00') {
        value = value.slice(2)
      }

      if (value.slice(0, 1) === '+') {
        value = value.slice(1)
      }

      if (value.slice(0, 1) === '0') {
        value = value.slice(1)
      }

      if (value.slice(0, 2) === '48') {
        value = value.slice(2)
      }

      return value
    },
    onlyDigitsStringWithLength (value, length) {
      value = this.onlyDigitsString(value)
      value = this.stripPhoneNumber(value)
      if (value.length > length) {
        return value.slice(0, length)
      }
      return value
    }
  }
}
</script>

<style lang="scss">
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: conic-gradient(at right center, rgb(17, 24, 39), rgb(75, 85, 99));
    @media (max-width: 740px) and (orientation: landscape) {
      padding: 25px;
    }
    @media (max-width: 480px) {
      padding: 0 25px;
    }

    &__inner {
      background: #fff;
      max-width: 350px;
      width: 100%;
      border-radius: 3px;
      padding: 55px;
      display: flex;
      flex-direction: column;
      box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      color: rgb(17, 24, 39);
      @media (max-width: 991px) {
        padding: 35px;
        width: auto;
      }
      @media (max-width: 320px) {
        padding: 15px;
        width: auto;
      }
      h1 {
        font-size: 30px;
        font-weight: 500;
        color: #333;
        line-height: 1.2;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        display: block;
        padding-bottom: 25px;
        @media (max-width: 480px) {
          font-size: 22px;
        }
      }

      form {
        font-size: 14px;
        display: flex;
        flex-direction: column;

        input:not([type="checkbox"]) {
          display: flex;
          border-radius: 3px;
          border: 1px solid #e6e6e6;
          margin: 10px 0;
          align-items: stretch;
          flex-grow: 1;
          font-size: 14px;
          background: #fff;
          padding: 15px;

          &:focus-within {
            border-color: #777;
            animation: pulse-animation-1 .5s;
          }

          &.invalid {
            border-color: #e53935;
          }
        }

        .input-box {
          display: flex;
          border-radius: 3px;
          overflow: hidden;
          border: 1px solid #e6e6e6;
          margin: 10px 0;
          align-items: stretch;

          &:last-child {
            margin-bottom: 0;
          }

          &:focus-within {
            border-color: #777;
            animation: pulse-animation-1 .5s;
          }

          .prefix {
            font-size: 14px;
            color: #777;
            padding: 17px 15px 13px 15px;
            line-height: 22px;
            background-color: #e6e6e6;
          }

          &.invalid {
            border-color: #e53935;
          }

          input {
            flex-grow: 1;
            font-size: 14px;
            background: #fff;
            border: none;
            outline: none;
            padding: 15px;
            margin: 0;

            &:focus-within {
              border: none;
              animation: none;
            }
          }
        }
        .form-group {
          font-family: system-ui, sans-serif;
          font-size: 12px;
          line-height: 13px;
          display: grid;
          color: #999;
          grid-template-columns: 1em auto;
          gap: 20px;
          margin-top: 10px;
          label {
            cursor: pointer;
            text-align: justify;
          }
          input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: #eee;
            width: 20px;
            height: 20px;
            border: 1px solid #e6e6e6;
            border-radius: 0.15em;
            transform: translateY(-0.075em);
            cursor: pointer;
            display: grid;
            place-content: center;
            &:before {
              content: "";
              width: 10px;
              height: 10px;
              clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
              transform: scale(0);
              transform-origin: bottom left;
              transition: 120ms transform ease-in-out;
              box-shadow: inset 1em 1em rgb(17, 24, 39);
              background-color: rgb(17, 24, 39);
              cursor: pointer;
            }
            &:checked {
              border: 1px solid rgb(17, 24, 39);
              &:before {
                transform: scale(1);
              }
            }
          }
        }
        .link {
          margin: 10px 0;

          a {
            color: #555;
            cursor: pointer;
            &:hover {
              color: rgb(17, 24, 39);
            }
          }
        }

        button {
          padding: 12px 16px;
          border-radius: 3px;
          border: 1px solid rgb(17, 24, 39);
          background: rgb(17, 24, 39);
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          cursor: pointer;
          text-transform: uppercase;
          transition: all .3s;
          margin-top: 25px;
          @media screen and (min-width: 640px) {
            &:hover {
              background: #333;
            }
          }
          &:disabled {
            background: rgb(75, 85, 99);
            cursor: default;
            border: 1px solid rgb(75, 85, 99);
          }

          &.outline {
            border: 1px solid rgb(17, 24, 39);
            background: #fff;
            color: rgb(17, 24, 39);
            margin: 0;
            cursor: pointer;
            @media screen and (min-width: 640px) {
              &:hover {
                border: 1px solid rgb(17, 24, 39);
                background: rgb(17, 24, 39);
                color: #fff;
                transform: scale(1);
              }
            }
          }
        }
      }

      .oauth-options {
        display: flex;
        justify-content: center;
        margin: 25px 0;
        gap: 10px;
        &__option {
          width: 45px;
          height: 45px;
          display: flex;
          padding: 5px;
          border: 1px solid #e6e6e6;
          border-radius: 3px;
          justify-content: center;
          align-items: center;
          transition: all .3s;
          cursor: pointer;
          img {
            max-height: 22px;
            width: auto;
            opacity: .8;
          }
          &:hover {
            border-color: #777;
            animation: pulse-animation-1 .5s;
            img {
              opacity: 1;
            }
          }
        }
      }

      .alternative {
        display: block;
        text-align: center;
        color: #999;
        margin: 25px;
      }
    }
  }

  @keyframes pulse-animation-1 {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(2, 119, 189, 0);
    }
  }
</style>
