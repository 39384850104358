import http from '../../services/http'
import Vue from 'vue'
// import settings from '../../services/settings'

const state = {
  accessToken: null,
  userInfo: null,
  error: null,
  authMethods: {}
}
const mutations = {
  setAccessToken (state, token) {
    state.accessToken = token
  },
  setUserInfo (state, user) {
    state.userInfo = user
  },
  setAvailableProviders (state, providers) {
    state.availableProviders = providers
  },
  setAuthMethods (state, methods) {
    state.authMethods = methods
  }
}
const getters = {
  getAccessToken (state) {
    return state.accessToken
  },
  getPhoneNumber (state) {
    return state.phoneNumber
  },
  getAuthState (state) {
    return state.authMethods
  }
}
const actions = {
  authRegisterWithPhone ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http
        .post('/auth/login/phone-number/', {
          phone_number: payload
        })
        .then((r) => {
          commit('chat/setLoading', false, { root: true })
          Vue.$toast.success('PIN został wysłany na podany numer telefonu. Zaloguj się.')
          resolve(r.data)
        })
        .catch((e) => {
          const responseCode = e.response.status
          if (responseCode === 400) {
            Vue.$toast.error('Podany numer telefonu jest nieprawidłowy.')
          } else if (responseCode === 425) {
            Vue.$toast.error('Odczekaj 5 minut i spróbuj ponownie.')
          } else if (responseCode === 429) {
            Vue.$toast.error('Odczekaj 5 minut i spróbuj ponownie.')
          } else {
            Vue.$toast.error('Wystąpił błąd. Spróbuj ponownie.')
          }

          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  authRegisterWithCaptcha ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http
        .post('/auth/login/recaptcha/', {
          user_name: payload.user_name,
          google_recaptcha_response: payload.google_recaptcha_response
        })
        .then((r) => {
          commit('chat/setLoading', false, { root: true })
          localStorage.setItem('accessToken', r.data.access_token)
          commit('setAccessToken', r.data.access_token)
          commit('chat/setAuthState', 'loggedIn', { root: true })
          Vue.$toast.success('PIN został wysłany na podany numer telefonu. Zaloguj się.')
          resolve(r.data)
        }).catch((e) => {
          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  authReset ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http
        .post('/auth/remind/pin-code/', {
          phone_number: payload
        })
        .then((r) => {
          commit('chat/setLoading', false, { root: true })
          Vue.$toast.success('PIN został wysłany na podany numer telefonu. Zaloguj się.')
          resolve(r.data)
        })
        .catch((e) => {
          const responseCode = e.response.status
          if (responseCode === 400) {
            Vue.$toast.error('Podany numer telefonu jest nieprawidłowy.')
          } else if (responseCode === 425) {
            Vue.$toast.error('Odczekaj 5 minut i spróbuj ponownie.')
          } else if (responseCode === 429) {
            Vue.$toast.error('Odczekaj 5 minut i spróbuj ponownie.')
          } else {
            Vue.$toast.error('Wystąpił błąd. Spróbuj ponownie.')
          }
          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  authLogin ({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http.post('/auth/login/pin-code/', payload)
        .then(r => {
          localStorage.setItem('accessToken', r.data.access_token)
          commit('setAccessToken', r.data.access_token)
          commit('chat/setAuthState', 'loggedIn', { root: true })
          // dispatch('messages/welcomeMessage', null, { root: true })
          commit('chat/setLoading', false, { root: true })
          resolve(r.data)
        })
        .catch((e) => {
          const responseCode = e.response.status
          Vue.$toast.error(responseCode === 400 ? 'Kod PIN jest nieprawidłowy.' : 'Wystąpił błąd. Spróbuj ponownie.')
          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  setAuthMethods ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http.get('/auth/login/enabled_methods')
        .then(r => {
          commit('setAuthMethods', r.data)
          commit('chat/setLoading', false, { root: true })
          resolve(r.data)
        })
        .catch((e) => {
          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  checkOauth ({ commit }, payload) {
    if (payload) {
      return new Promise((resolve, reject) => {
        if (payload) {
          localStorage.setItem('accessToken', payload)
          commit('setAccessToken', payload)
          commit('chat/setAuthState', 'loggedIn', { root: true })
          commit('chat/setLoading', false, { root: true })
          window.history.replaceState({}, document.title, '/')
          resolve(payload)
        }
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
