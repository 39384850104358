<template>
  <div class="prize" :class="{ received: !isAvailable }">
    <div class="mask" v-if="!isAvailable">
      <span class="label" v-if="isUsed">Wykorzystano</span>
    </div>
    <div class="picture">
      <template v-if="prize.prize_code && showCode">
        <canvas ref="code" class="code" :data-type="prize.prize_code.type"></canvas>
        <code>{{ prize.prize_code.code }}</code>
      </template>
      <img :src="prize.prize.image_url" :alt="prize.prize.title" v-else/>
      <span class="label" v-if="prize.prize.type === 'DEFAULT' && !isNaN(prize.prize.remaining_codes)">Pozostało: <strong>{{ prize.prize.remaining_codes }}</strong></span>
    </div>
    <div class="content">
      <h4>{{ prize.prize.title }}</h4>

      <button class="info" @click="$emit('openDescription', {...prize.prize, status: isAvailable})" :disabled="isUsed">Więcej informacji</button>

      <button class="collect" @click="isAvailable && canReceive ? $emit('openConfirmation', prize.prize ) : false" v-if="!prize.prize_code" :disabled="!isAvailable || !canReceive">
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_2659)">
            <path d="M1.25 10.5C1.25 10.9148 1.58516 11.25 2 11.25H5.75V7.5H1.25V10.5ZM7.25 11.25H11C11.4148 11.25 11.75 10.9148 11.75 10.5V7.5H7.25V11.25ZM11.75 3.75H10.7633C10.9086 3.46641 11 3.15234 11 2.8125C11 1.67578 10.0742 0.75 8.9375 0.75C7.9625 0.75 7.33203 1.24922 6.52344 2.35078C5.71484 1.24922 5.08437 0.75 4.10938 0.75C2.97266 0.75 2.04688 1.67578 2.04688 2.8125C2.04688 3.15234 2.13594 3.46641 2.28359 3.75H1.25C0.835156 3.75 0.5 4.08516 0.5 4.5V6.375C0.5 6.58125 0.66875 6.75 0.875 6.75H12.125C12.3313 6.75 12.5 6.58125 12.5 6.375V4.5C12.5 4.08516 12.1648 3.75 11.75 3.75ZM4.10703 3.75C3.58906 3.75 3.16953 3.33047 3.16953 2.8125C3.16953 2.29453 3.58906 1.875 4.10703 1.875C4.57344 1.875 4.91797 1.95234 6.125 3.75H4.10703ZM8.9375 3.75H6.91953C8.12422 1.95703 8.45937 1.875 8.9375 1.875C9.45547 1.875 9.875 2.29453 9.875 2.8125C9.875 3.33047 9.45547 3.75 8.9375 3.75Z" fill="#0277BD"/>
          </g>
          <defs>
            <clipPath id="clip0_1_2659">
              <rect width="12" height="12" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg>
        Odbierz nagrodę
      </button>

      <button class="flat" @click="downloadCode" v-if="prize.prize_code" :disabled="isUsed">
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_354_118)">
            <path d="M5.5625 0H7.4375C7.74922 0 8 0.250781 8 0.5625V4.5H10.0555C10.4727 4.5 10.6812 5.00391 10.3859 5.29922L6.82109 8.86641C6.64531 9.04219 6.35703 9.04219 6.18125 8.86641L2.61172 5.29922C2.31641 5.00391 2.525 4.5 2.94219 4.5H5V0.5625C5 0.250781 5.25078 0 5.5625 0ZM12.5 8.8125V11.4375C12.5 11.7492 12.2492 12 11.9375 12H1.0625C0.750781 12 0.5 11.7492 0.5 11.4375V8.8125C0.5 8.50078 0.750781 8.25 1.0625 8.25H4.50078L5.64922 9.39844C6.12031 9.86953 6.87969 9.86953 7.35078 9.39844L8.49922 8.25H11.9375C12.2492 8.25 12.5 8.50078 12.5 8.8125ZM9.59375 10.875C9.59375 10.6172 9.38281 10.4062 9.125 10.4062C8.86719 10.4062 8.65625 10.6172 8.65625 10.875C8.65625 11.1328 8.86719 11.3438 9.125 11.3438C9.38281 11.3438 9.59375 11.1328 9.59375 10.875ZM11.0938 10.875C11.0938 10.6172 10.8828 10.4062 10.625 10.4062C10.3672 10.4062 10.1562 10.6172 10.1562 10.875C10.1562 11.1328 10.3672 11.3438 10.625 11.3438C10.8828 11.3438 11.0938 11.1328 11.0938 10.875Z" fill="#0277BD"/>
          </g>
          <defs>
            <clipPath id="clip0_354_118">
              <rect width="12" height="12" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg>
        Pobierz kod
      </button>

      <button class="collect" @click="showCode = !showCode" v-if="prize.prize_code" :disabled="isUsed">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 10.5V1.5H0.421875V10.5H0ZM0.629461 10.4936V1.5H0.84375V10.4936H0.629461ZM1.26562 10.4936V1.5H1.47321V10.4936H1.26562ZM2.31696 10.4936V1.5H2.52455V10.4936H2.31696ZM3.16071 10.4936V1.5H3.57588V10.4936H3.16071ZM4.21205 10.4936V1.5H4.41963V10.4936H4.21205ZM4.63392 10.4936V1.5H4.84151V10.4936H4.63392ZM5.0558 10.4936V1.5H5.26338V10.4936H5.0558ZM5.89287 10.4936V1.5H6.31474V10.4936H5.89287ZM6.9442 10.4936V1.5H7.36608V10.4936H6.9442ZM7.78793 10.4936V1.5H8.20983V10.4936H7.78793ZM8.6317 10.4936V1.5H9.0536V10.4936H8.6317ZM9.26116 10.4936V1.5H9.68304V10.4936H9.26116ZM10.3192 10.4936V1.5H10.9487V10.4936H10.3192ZM11.1562 10.4936V1.5H11.3705V10.4936H11.1562ZM11.5781 10.5V1.5H12V10.5H11.5781Z" fill="#0277BD"/>
        </svg>
        {{ showCode ? 'Ukryj' : 'Pokaż' }} kod
      </button>
    </div>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'

export default {
  name: 'Prize',
  props: {
    prize: {
      required: true
    },
    status: {
      type: Object
    }
  },
  data () {
    return {
      showCode: false
    }
  },
  computed: {
    isUsed () {
      return this.prize.prize_code && this.prize.prize_code.status === 'USED'
    },
    isAvailable () {
      if (this.prize.prize_code && this.prize.prize_code.status === 'USED') {
        return false
      }

      if (this.prize.prize.type === 'DEFAULT' && this.prize.prize.remaining_codes === 0) {
        return false
      }

      return true
    },
    canReceive () {
      if (!this.status) {
        return false
      }
      return this.status.is_eligible
    }
  },
  updated () {
    const code = this.$refs.code
    if (code && this.prize.prize_code && this.prize.prize_code.type === 'EAN8') {
      JsBarcode(code).EAN8(this.prize.prize_code.code, {
        displayValue: false,
        height: 80
      }).render()
    }
    if (code && this.prize.prize_code && this.prize.prize_code.type === 'QR') {
      QRCode.toCanvas(code, this.prize.prize_code.code, { margin: 0, width: 80 })
    }
  },
  methods: {
    downloadCode () {
      this.showCode = true
      this.$nextTick(() => {
        const code = this.$refs.code
        const link = document.createElement('a')
        link.download = `kod_${this.prize.prize_code.code}.jpg`
        link.href = code.toDataURL()
        this.showCode = false
        link.click()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.prize {
  border-radius: 6px;
  border: 1px solid #EEE;
  display: flex;
  width: 200px;
  max-width: 200px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #fff;
  transition: all .2s;
  position: relative;
  &:not(.received):hover {
    border-color: #0277BD;
    button.collect:not(:disabled) {
      animation: pulse-animation-2 2s infinite;
    }
  }
  .mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(217, 217, 217, 0.5);
    z-index: 1;
    .label {
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: #bf360c;
      border-radius: 15px;
      border: 1px solid #bf360c;
      background: rgba(255, 204, 188, 0.85);
      z-index: 2;
    }
  }
  .picture {
    height: 125px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    canvas {
      margin: 10px auto 0 auto;
      &[data-type="EAN8"] {
        margin-top: 0;
        max-height: 100px;
      }
    }
    code {
      position: absolute;
      bottom: 0;
      padding: 5px 10px;
      background: #eee;
      font-size: 14px;
      border-radius: 5px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .label {
      z-index: 1;
      position: absolute;
      top: 5px;
      right: 5px;
      display: inline-flex;
      padding: 4px 14px;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: rgba(117, 117, 117, 1);
      border-radius: 15px;
      background: #F5F5F5;
      strong {
        font-weight: 500;
      }
    }
  }

  .content {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;

    h4 {
      color: #01579B;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin: 5px 0 0 0;
      height: 45px;
      text-align: center;
    }

    button {
      display: flex;
      padding: 8px 24px;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 15px;
      background: #F5F5F5;
      border: none;
      font-size: 13px;
      color: #757575;
      cursor: pointer;
      transition: all .2s;
      &:hover {
        background: #eee;
      }

      &.flat {
        border: 1px solid #E1F5FE;
        background: #fff;
        color: #0277BD;
        font-weight: 600;
        &:hover {
          background: #0277BD;
          color: #E1F5FE;
          svg {
            path {
              fill: #E1F5FE;
            }
          }
        }
      }

      &.collect {
        background: #E1F5FE;
        color: #0277BD;
        font-weight: 600;
        &:hover {
          background: #0277BD;
          color: #E1F5FE;
          svg {
            path {
              fill: #E1F5FE;
            }
          }
        }
      }

      &:disabled {
        background: #F5F5F5;
        color: #ccc;
        svg {
          path {
            fill: #ccc;
          }
        }
      }
    }
  }
}
</style>
