<template>
  <transition name="fade-in" v-if="text">
    <div class="message-limit">{{ getSettings.translations[text] }}</div>
  </transition>
</template>

<script>
import settings from '../mixins/settings'

export default {
  name: 'MessageLimit',
  mixins: [settings],
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.message-limit {
  margin: 0 0 15px 0;
  display: flex;
  padding: 6px 10px;
  border-radius: 6px;
  color: #e65100;
  background: #fff3e0;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
</style>
