<template>
  <div class="chatResponse">
    <transition name="fade-in">
      <div class="messageTip messageTip__him" v-if="question">
        <div class="message">{{ question | urlify }}</div>
      </div>
    </transition>
    <transition name="fade-in">
      <div class="messageTip messageTip__own" :class="{ welcome: type === 'welcome' }" v-if="answer">
        <div class="message" v-html="prepareAnswer"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import urlify from '../../filters/urlify'
export default {
  name: 'MessageTip',
  props: {
    question: {
      required: true,
      type: String
    },
    answer: {
      type: String
    },
    index: {
      type: Number
    },
    type: {
      type: String
    },
    link: {
      type: String
    }
  },
  filters: {
    urlify
  },
  computed: {
    prepareAnswer () {
      const answer = this.$options.filters.urlify(this.answer)
      if (!this.link) {
        return answer
      }
      const movieID = this.getYTMovieID(this.link)

      if (!movieID) {
        return answer
      }

      return answer + this.prepareYTEmbed(movieID)
    }
  },
  methods: {
    getYTMovieID (url) {
      const regExp =
        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

      const match = url.match(regExp)

      if (match && match[2].length === 11) {
        return match[2]
      }

      return false
    },
    prepareYTEmbed (id) {
      return `<iframe width="420" height="315" allowfullscreen src="https://www.youtube.com/embed/${id}"></iframe>`
    }
  }
}
</script>

<style lang="scss" scoped>
.messageTip {
  margin: 0 0 15px 0;
  display: flex;

  .message {
    padding: 6px 10px;
    max-width: calc(100% - 15px);
    border-radius: 0 6px 6px 6px;
    color: #2C2C2E;
    background: #F2F2F7;
    position: relative;
    font-size: 13px;
    line-height: 18px;
    margin-left: 8.72px;

    &:before {
      content: '';
      position: absolute;
      width: 8.72px;
      height: 11.12px;
      left: -8px;
      top: 0;
      clip-path: polygon(0 0, 100% 100%, 100% 0);
      background: #F2F2F7;
    }
  }
  a {
    color: #131313;
    text-decoration: none;
    overflow-wrap: break-word;
    border-bottom: 1px solid #131313;
    &:hover {
      border-bottom: 0;
    }
  }

  &::v-deep {
    .country-flag {
      max-width: 18px;
      height: auto;
    }
    iframe {
      max-width: 100%;
      height: auto;
      margin: 10px 0 0 0;
      display: block;
    }
  }

  &__own {
    &.welcome {
      margin-top: 10px;
      .message {
        min-width: 230px;
      }
    }
  }
  &__him {
    flex-direction: row-reverse;

    .message {
      border-radius: 6px 0 6px 6px;
      background: #131313;
      color: #fff;
      margin-right: 8px;

      &:before {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        width: 8.72px;
        height: 11.12px;
        right: -8.72px;
        top: 0;
        display: inline-block;
        clip-path: polygon(0 0, 0 100%, 100% 0);
        background: #131313;
      }
    }
  }
}

.fade-in-enter-active {
  transition: all 0.3s ease;
}

.fade-in-leave-active {
  transition: all 0.3s ease;
}

.fade-in-enter, .fade-in-leave-to {
  opacity: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        background-color: mix(#fff, #00005c, 84%);
        &:before {
          box-shadow: inset 0 0 0 0.4375em #00005c;
        }
      }
    }
  span {
      display: flex;
      align-items: center;
      padding: 0.375em 0.75em 0.375em 0.375em;
      border-radius: 99em; // or something higher...
      transition: 0.25s ease;
      &:hover {
        background-color: mix(#fff, #00005c, 84%);
      }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-color: #fff;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        margin-right: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em #00005c;
      }
    }
}

// Codepen spesific styling - only to center the elements in the pen preview and viewport
.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
// End Codepen spesific styling
</style>
