const chatTypeS = process.env.VUE_APP_CHAT_TYPE
const chatLanguageS = process.env.VUE_APP_LANG
const translations = {
  pl: {
    title: 'Galeria Łódzka',
    subtitle: 'Obsługiwane przez',
    type_message: 'Zapytaj mnie o coś...',
    send: 'Wyślij',
    welcome: 'Cześć, w czym mogę Ci pomóc?',
    limit_exceeded: 'Niestety nie mogę kontynuować, ponieważ limit odpowiedzi został wyczerpany.',
    validation_failed: 'Twoja wiadomość może nie dotyczyć konkursu lub Galerii Łódzkiej - nie odpowiem na Twoje pytanie',
    language_not_allowed: 'Twoja wiadomość może nie dotyczyć konkursu lub Galerii Łódzkiej - nie odpowiem na Twoje pytanie',
    disclaimer: 'AICA może generować niedokładne informacje o ludziach, miejscach lub faktach.'
  },
  en: {
    title: 'Galeria Łódzka',
    subtitle: 'Powered by',
    type_message: 'Ask me something...',
    send: 'Send',
    welcome: 'Hi, how can I help you?',
    limit_exceeded: 'Unfortunately, I cannot continue because the reply limit has been reached.',
    validation_failed: 'Twoja wiadomość może nie dotyczyć konkursu lub Galerii Łódzkiej - nie odpowiem na Twoje pytanie',
    language_not_allowed: 'Twoja wiadomość może nie dotyczyć konkursu lub Galerii Łódzkiej - nie odpowiem na Twoje pytanie',
    disclaimer: 'AICA może generować niedokładne informacje o ludziach, miejscach lub faktach.'
  }
}
export default {
  type: chatTypeS,
  language: chatLanguageS,
  translations: translations[chatLanguageS],
  all_translations: translations
}
